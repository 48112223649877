import { Component, Renderer2 } from '@angular/core';
import { TranslationService } from './core/services/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  activeLang: string = null;
  constructor(
    private translationService: TranslationService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.translationService.init();
    this.translationService.languages.subscribe((langs) => {
      const lang = langs
        .filter((lang: any) => lang.active)
        .map((lang: any) => lang.value);
      this.renderer.removeClass(document.body, this.activeLang);
      this.activeLang = lang;
      this.renderer.addClass(document.body, lang);
    });
  }
}
