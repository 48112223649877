import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class TranslationService {
  private default = "en";
  private _languages: Array<any> = [
    {
      name: "English",
      value: "en",
      active: false
    },
    {
      name: "Lao",
      value: "la",
      active: false
    }
  ];
  public languages: BehaviorSubject<any> = new BehaviorSubject(this._languages);

  constructor(private translate: TranslateService) {}

  private storeLanguage(value): void {
    localStorage.setItem("language", value);
  }
  private loadLanguage(): void {
    const value = localStorage.getItem("language") || this.default;
    this.switchLanguage(value);
  }

  public init() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(this.default);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.loadLanguage();
  }

  public switchLanguage(value: string) {
    this.translate.use(value);
    this._languages.forEach(lang => {
      if (lang.value === value) {
        lang.active = true;
      } else {
        lang.active = false;
      }
    });
    this.languages.next(this._languages);
    this.storeLanguage(value);
  }

  public async get(path: string): Promise<string> {
    return this.translate.get(path).toPromise();
  }
}
