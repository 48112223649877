import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SearchConnectorService {
  constructor() {}

  // Value to subscribe from child components
  currentValue: BehaviorSubject<string> = new BehaviorSubject('');

  // Value to subscribe from parent component to reset input value
  sourceValue: BehaviorSubject<string> = new BehaviorSubject('');
}
