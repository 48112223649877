import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { IUser } from 'src/app/shared/interfaces/user.interface';
import { environment } from 'src/environments/environment';
import { UtilsService } from './utils.service';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private utils: UtilsService) {}

  login(requestObj: { email: string; password: string }): Observable<any> {
    // this.storeUserData('test');
    // return of(true);

    const url = `${environment.api_uri}/login`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(url, requestObj, httpOptions).pipe(
      tap((res: { API_KEY: string; ROLES: string[] }) => {
        // Save token and user object to localStorage
        let role = res.ROLES[0];
        this.storeUserData(res.API_KEY, role);
      }),
      catchError(this.utils.handleError('error'))
    );
  }

  logout(): Observable<any> {
    // this.clearStorage();
    // return of(true);

    const url = `${environment.api_uri}/logout`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'No-Auth': 'true', // Disable auth interceptor
      }),
    };
    return this.http.post(url, {}, httpOptions).pipe(
      tap(() => {
        this.clearStorage();
      }),
      catchError(() => {
        // Continue logout even if server returns error
        this.clearStorage();
        return of(null);
      })
    );
  }

  get token(): string {
    return localStorage.getItem('API_KEY');
  }

  get role(): string {
    return localStorage.getItem('ROLE');
  }

  private clearStorage(): void {
    localStorage.removeItem('API_KEY');
  }
  private storeUserData(token?: string, role?: string): void {
    if (token) {
      localStorage.setItem('API_KEY', token);
    }
    if (role) {
      localStorage.setItem('ROLE', role);
    }
  }
}
